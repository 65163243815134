@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto';
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
}
