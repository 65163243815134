.login_page {
  display: flex;
  height: 70vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1 {
  color: #3d3d3d;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}


.login_page__form__input_container {
  display: flex;
  flex-direction: column;
}

.login_page__form__input_container:not(:first-child) {
  margin-top: 12px;
}

span {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #3D3D3D;
}

input {
  height: 40px;
  width: 400px;
  color: #717171;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  border-color: transparent;
  border-radius: 8px;
}

 button {
  height: 50px;
  width: 200px;
  margin-top: 32px;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  background-color: #044783;
  border-color: transparent;
  border-radius: 8px;
  transition-property: background-color;
  transition-duration: 0.3s;
  cursor: pointer;
}

button:hover {
  background-color: blue;
}

.login_page__form > a {
  margin-top: 12px;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  text-decoration: none;
  color: #044783;
  transition-property: color;
  transition-duration: 0.3s;
  cursor: pointer;
}

.login_page__form > a:hover {
  color: blue;
}




