.nav__toolbar {
    align-items: center;
    text-align: center;
    position: sticky;
    top: 0;
    min-height: 6vh;
    width: 100%;
    z-index: 100;
    overflow: hidden;
    background-color: white;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.burger--toolbar {
    cursor: pointer;
    margin-left: 50px;
    
}

.burger--toolbar div {
    width: 30px;
    height: 5px;
    background-color: rgb(21, 6, 6);
    margin: 6px;
    margin-left: 0;
    transition: all 0.3s ease;
}


.toggle .burger--line--1 {
    transform: rotate(-45deg) translate(-7px, 8px);
}
  
  .toggle .burger--line--2 {
    opacity: 0;
}
    
  .toggle .burger--line--3 {
    transform: rotate(45deg) translate(-7px, -8px);
}

.button__login{
  color: #00B03B;
  border: 1px solid #00B03B;
  background: none;
  font-family: 'Poppins';
  font-size: 16px;
  padding: 6px 22px;
  margin-right: 50px;
}

.button__login:hover{
  cursor: pointer;
  color: #fff;
  background:#00B03B;
  transition: 0.4s;
}