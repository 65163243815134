.nav__toolbar {
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    min-height: 6vh;
    z-index: 100;
    background-color: white;
    border-bottom: 0.5px solid rgb(196, 192, 192);
    width: 100%;
}

.nav__toolbar::before{
    content: "";
    width: 130px;
}
