.SessionsPanel {
    display: flex;
    flex-direction: column;
    width: 35%;
    height: fit-content;
    border-radius: 10px;
    background-color:#292929;
    color: white;
    margin-right: 15px;
}

.SessionsPanel ul {
    list-style: none;
    margin: 64px 0 64px 0;
} 

.SessionsPanel li {
    font-size: 25px;
    line-height: 124px;
    cursor: pointer;
    margin: 0;
}

.SessionsPanel li:hover {
    background-color: #373636;
}