.SessionItem {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 8px;
    background-color: #292929;
    padding: 10px;
    font-size: 22px;
    color: white;
    margin: 7px 0;
}