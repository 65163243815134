.UserMenu {
    display: flex;
    align-items: center;
    justify-content: space-aroun;
    cursor: pointer;
    width: 130px;
}


.user__menu__welcome {
    font-size: 22px;
    margin-right: 150px;
    display: flex;
    flex-direction: column;
    text-align: left;

}

.user__menu__name {
    color: rgb(57, 121, 121);
}
