.dashboard_template_container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.dashboard_template__main {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-right: 100px;
  padding-bottom: 120px;
  padding-left: 100px;
}

.dashboard_template__main__box {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 56px;

  background-color: #F5F5F5;
}
