@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
body {
    margin: 0;
    padding: 0;
    background-color: whitesmoke;
    font-family: 'Roboto';
  }


.admin__panel {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
}

.admin__panel h1 {
    text-align: center;
    font-size: 32px;
    color: #3D3D3D;
}

.admin__panel__board {
    display: flex;
    padding: 30px;
}