.data__card {
    margin-bottom: 50px;
    overflow: auto;
    width: 87%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media (min-width: 1280px){
    .data__card{
        width: 1200px;
    }
}