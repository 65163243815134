.recover_password_page {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.recover_password_page > h1 {
  color: #3d3d3d;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}

.recover_password_page > p {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #3d3d3d;
}

.recover_password_page__form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recover_password_page__form__input_container {
  display: flex;
  flex-direction: column;
}

.recover_password_page__form__input_container > span {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #3D3D3D;
}

.recover_password_page__form__input_container > input {
  height: 40px;
  width: 400px;

  color: #717171;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  border-color: transparent;
  border-radius: 8px;
}

.recover_password_page__form > button {
  height: 50px;
  width: 200px;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;

  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;

  background-color: #044783;
  border-color: transparent;
  border-radius: 8px;

  transition-property: background-color;
  transition-duration: 0.3s;

  cursor: pointer;
}

.recover_password_page__form > button:hover {
  background-color: blue;
}
