.footer__ufba {
    position: relative;
    width: 100%;
    color: white;
    display: flex;
    background: #0B3665;
    justify-content: space-between;
    height: 25vh;
    align-items: center;
    
}

.footer__right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer__left{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.footer__left h2,h3,h5{
    height: 10px;
}

.footer__left h3{
    margin-left: -26px;
}

.footer__left a:link {
    color: white;
    text-decoration: none;
  }
  
  /* visited link */
.footer__left a:visited {
    color: white;
}
  
  /* mouse over link */
.footer__left a:hover {
    color: rgb(172, 172, 172);
    transition: 0.3s;
}